::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  visibility: hidden;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(121, 118, 118);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}
.container {
  margin-left: 4rem;
  border-radius: 1.2rem;
  margin-top: 2rem;
  padding-right: 20px;
  width: 92vw;
  height: 85vh;
  display: flex;
  justify-content: space-between;
  background-color: rgb(216, 216, 216);
  box-shadow: 2px 2px 2px 2px gray;
}
.imagesection {
  height: 100%;
  width: 20%;
  border-radius: 1.2rem 0 0 1.2rem;
}
.imagediv {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
}
.imagediv > img {
  width: 50%;
  height: 30%;
  border-radius: 50%;
}
.detailsdiv {
  margin-top: 4rem;
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.detailsdiv > div > label {
  width: 200px;
  color: rgb(77, 77, 77);
  font-weight: bold;
}
.sectionde {
  overflow-y: scroll;
  height: 100%;
  padding: 2rem;
  width: 40%;
  background-color: rgb(184, 184, 184);
  border-left: 2px;
}
.projectDetails {
  font-size: 0.95rem;
}
.projectDetails > label {
  font-weight: bolder;
}
.mainsection {
  background-color: white;
  height: 85vh;
  margin: 1rem 2rem;
  border-radius: 0.2rem;
  box-shadow: 0px 0px 15px 0.5px rgb(222, 222, 222);
  padding: 4rem;
}
.main{
  width:95vw
}
.addmain{
  transform: translate(18%);
  width:70vw
}
.detailsection {
  width:100%;
  display: flex;
}
.formitem {
  width: 250px;
  margin-bottom: 1rem;
}
.formsection {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 65vh;
}
.sectionWidth{
  width:50%
}
.fullWidth{
  width:200vw;
  transform: translate(10%);
}
.formitem1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.formitem2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.formitem3 {
  margin-top: 20px;
  display: flex;
  width: 100%;
}
.formitem4{
    margin-top:4rem;
    display: flex;
}
.formitem5{
  display: flex;
  justify-content: space-between;
}
.documentsection{
    overflow-y: scroll;
    width:50%;
    height:60vh;
    margin-left:4rem
}
.nameItem{
  width: 48%;
  display: flex;
  flex-direction: column;
}
