#slider-container{
    position: fixed;
    z-index: 100;
    top:43px;
    left: 100px;
}

#slider{
    width:500px;
    height: 800px;
    /* border: 1px solid black; */
}

#slider h3{
    color: white;
    width: 330px;
    font-size: 22px;
    margin: 0;
}

#slider img{
    margin-top: 60px;
}
.slide-navigationButtons{
    display: inline-block;
    margin-left: 50%;
}

.rounded-button{
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-left: 10px
    /* padding-left: 10px; */
}

.slider-activeButton{
    background-color: #035fff;
}

.blue-curve{
    background:linear-gradient(to bottom right, #045bfc 61%, #4842d8 68%);
}

.red-curve{
    background:linear-gradient(to bottom right, #e46a6a  61%, #f70101 68%);
}

.yellow-curve{
    background:linear-gradient(to bottom right, #eece6e 61%, #ffbe26 68%);
}
