.mainSection{
    height:85vh;
    padding: 2rem;
    margin:2rem;
    display: flex;
    justify-content: center;
}
.contentsection{
    box-shadow: 0px 0px 15px 0.5px rgb(222, 222, 222);
    border-radius: 1rem;
    width:40vw;
    height:100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.dropdiv{
    margin:1rem;
    height: 53vh;
}
.dropcontainer{
    
    border-radius: 0.5rem;
    display:flex;
    align-items:center
}
.dropzone{
    background-color: rgb(241, 240, 240);
    border:2px dashed gray;
    border-radius:1rem;
    width: 100%;
    height:20vh;
    padding: 3rem 0 3rem 0;
    display: flex;
    justify-content: center;
}
.dropzone > p{
    color:gray;
    margin-left:10px
}
.fileimg{
    width:4rem
}
.template{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:0.2rem;
    height:50px
}
.template > a{
    text-decoration:none;
    font-size: 0.9rem;
    margin:0.5rem;
}
.submitdiv{
    margin: 5rem;
}
.btnsubmit{
    display: flex;
}
.btnsubmit > div {
    width: 100%;
    display: flex;
    justify-content: center;
}
.errordiv{
    width: inherit;
    color:red;
    text-align: center;
    overflow-wrap: break-word;
    height: 20px;
}
.successdiv{
    color:green;
    text-align: right;
    font-weight: bold;
    margin: 0px;
    height:22px
}
.Note{
    font-size: 0.8rem;
    margin: 0px;
}