@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');

body{
    height: 100vh;
    /* width: 100vw; */
    overflow:auto;
    font-family: 'Open Sans', sans-serif;
}

#preLogin-container{
    display: flex;
    position: unset;

}
#perLogin-TestApp{
  position: relative;  
}



#leftBox{
    display: inline-block;
    width: 71%;
    height: 100vh;
}

#curve{
   position: fixed;
    width: 314%;
    height: 362%;
    top: -117%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 80%;
    right: 42%;
}

#rightBox{
   display: block;
   width: 49%;
   position: inherit;
   height: 100vh;
   justify-content: center;
   align-items: center;
   padding-top: 85px;
   padding-left: 80px;
   font-family: 'Open Sans', sans-serif;
}

.tokenTime{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

#login-form{
    background-color: white;
    width:300px;
    height: auto;
    padding: 20px 20px;
    border-radius: 7px;
    box-shadow: 0 2px 10px 3px #E3E3E3;
    margin-top: 120px;
    padding-top: 15px;
}

#login-form input{
    width: 100%;
    height: 30px;
    border-radius: 3px;
}

.submit-button{
    width: 100%;
    height: 32px;
    background-color: #035fff ;
    font-size: 13px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    box-shadow:0 0 4px 3px rgba(3, 95, 255, 0.2);
    border:none;
}

.loginForm-title{
    padding-top: 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    padding-bottom: 15px;
    margin-top: 0;
    color:#4d5053  ;
}
.testVerify{
    margin-left: 30%;
    margin-top: 12%;
    background-color: white;
}
.marginBottom{
    margin-bottom: 9px;
}

input::-webkit-input-placeholder {
    color: #a7a7a7 !important;
    font-style: italic !important;
    font-size: 12px;
  }
  
  input:-moz-placeholder {
    /* Firefox 18- */
    color: #b7a7a7 !important;
    font-style: italic !important;
    font-size: 12px;

  }
  
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #b7a7a7 !important;
    font-style: italic !important;
    font-size: 12px;

}
  
  
input:-ms-input-placeholder {
    color: #b7a7a7 !important;
    font-style: italic !important;
}

#loginPageTitle{
    display: flex;
    width:300px;
    align-items: baseline;
}
 
#loginPageTitle img{
    width:33px;
    height: 18px;
}

#loginPageTitle h4{
    font-weight: bolder;
    font-size: 22px;
    padding: 0 10px 0 15px;
    margin-top: 0;
}

#loginPageTitle span{
    color:#96a1ab;
    font-size: 12px;
    margin-top: 6px;
}

.marginTop{
    margin-top:20px;
    font-size: 12px;
    font-weight: bolder;
    color:#035fff;
    width:300px;
}

.company-logo{
    width: 250px;
    margin-top: 110px;
    position: fixed;
    top:65%;
}

.powered{
    color:#96a1ab;
    font-size: 12px;
    font-weight: initial;
    margin-bottom:8px;
    margin-right:50px;


}

#forgot-password{
    color:#035fff !important;
}


.fullSize{
    width: 100%
}
@media(max-width:997){
    #slider img{
        width:75%;
    }
}
@media screen and (max-width: 600px) {
    .testVerify{
        margin: 65px 10px 0px 10px;
    }
}
@media(max-width:768px){
    #leftBox{
        position: absolute;
        left:0px;
        display: block;
        width: 100%;
  margin-left:0px;
    float: left;
   
    }
    #slider{
        display: none;

    }
    #curve{
        position: fixed;
         width: 314%;
         height: 362%;
         top: -117%;
         border-top-right-radius: 50%;
         border-bottom-right-radius: 80%;
         right: -22%;
     }
    
    #rightBox{
        position:absolute;
        left:-50px;
        margin:0px;
    }
    .powered{
        margin-right:0;
    }
    }
   select.selectProject.form-control{
    padding: 1px 0px !important;
   }
   .overflowAuto{
      overflow: auto;
   }
   .calendar{
       width:792px !important;
   }



