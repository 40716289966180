.table{
    box-sizing: border-box;
    width:94%;
    font-size:small;
    box-shadow: 0px 0px 1px 0.5px gray;
    border-radius:5px;
    margin:3px;
}
.header{
    height: 50px;
    background-color: rgb(46, 73, 159);
    margin: 0px;
    color:white;
    padding: 0px;
    width: 100%;
}
.tablerow{
    background-color: rgb(241, 238, 238);
    height:40px
}
a{
    cursor: pointer;
}
.popup{
    background-color:white;
    padding:1.5rem;
    position:absolute;
    top:50%;
    left:70%;
    z-index:50;
    border-radius:5px;
    box-shadow: 0px 0px 15px 0.5px gray;
}
.prompt-box{
    background-color: white;
    padding:1rem;
    position:absolute;
    top:30%;
    width:43vw;
    height:60vh;
    border-radius:5px;
    box-shadow: 0px 0px 15px 0.5px gray;
}

