@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0;
  /* background-color: aliceblue; */
}
.inputWidth{
  padding: 5px;
}

.sidebar {
  padding: 15px;
}
.menuItem{
  display: flex;
  align-items: center;
  height:30px;
  margin-bottom:2px
}
.menuIcon{
  display: flex;
  color: gray;
  align-items: center;
  margin:0px 3px;
}
.navNames {
  color: blue;
  margin-left: 0px;
  text-decoration: none;
  display: block;
}
.navNames:hover {
  color: grey;
}

.inputWidth {
  width: 100% !important;
}
.pageHeading {
  position: absolute;
  top: 0;
  left: calc((89% - 372px) / 2);
}

.formSize {
  padding: 40px 0px 0px 20px !important;
}

.inner-header {
  display: flex;
  position: absolute;
  left: 50px;
  top: 0;
  padding: 15px;
  justify-content: center;
  width: calc(100% - 50px);
}

.inner-header div {
  margin-left: auto;
}

.inner-header div.margin-auto {
  margin: 0 auto;
}

.page-title {
  font-size: 20px;
  margin: 0;
}

.without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}
input[type="time"]::-webkit-clear-button {
  -webkit-appearance: none;
  -o-appearance: none;
  appearance: none;
  margin: -10px;
}
.hideChckbox {
  display: none !important;
}
.recordBtn {
  position: absolute;
  margin-top: 20px;
}
.stopBtn {
  position: absolute;
  margin-left: 70px;
  margin-top: 20px;
}
.MuiAutocomplete-hasClearIcon div.MuiAutocomplete-endAdornment {
  top: calc(50% - 30px) !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  visibility: hidden;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(121, 118, 118);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}
.container {
  margin-left: 4rem;
  border-radius: 1.2rem;
  margin-top: 2rem;
  padding-right: 20px;
  width: 92vw;
  height: 85vh;
  display: flex;
  justify-content: space-between;
  background-color: rgb(216, 216, 216);
  box-shadow: 2px 2px 2px 2px gray;
}
.imagesection {
  height: 100%;
  width: 20%;
  border-radius: 1.2rem 0 0 1.2rem;
}
.imagediv {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
}
.imagediv > img {
  width: 50%;
  height: 30%;
  border-radius: 50%;
}
.detailsdiv {
  margin-top: 4rem;
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.detailsdiv > div > label {
  width: 200px;
  color: rgb(77, 77, 77);
  font-weight: bold;
}
.sectionde {
  overflow-y: scroll;
  height: 100%;
  padding: 2rem;
  width: 40%;
  background-color: rgb(184, 184, 184);
  border-left: 2px;
}
.projectDetails {
  font-size: 0.95rem;
}
.projectDetails > label {
  font-weight: bolder;
}
.mainsection {
  background-color: white;
  height: 85vh;
  margin: 1rem 2rem;
  border-radius: 0.2rem;
  box-shadow: 0px 0px 15px 0.5px rgb(222, 222, 222);
  padding: 4rem;
}
.main{
  width:95vw
}
.addmain{
  -webkit-transform: translate(18%);
          transform: translate(18%);
  width:70vw
}
.detailsection {
  width:100%;
  display: flex;
}
.formitem {
  width: 250px;
  margin-bottom: 1rem;
}
.formsection {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 65vh;
}
.sectionWidth{
  width:50%
}
.fullWidth{
  width:200vw;
  -webkit-transform: translate(10%);
          transform: translate(10%);
}
.formitem1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.formitem2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.formitem3 {
  margin-top: 20px;
  display: flex;
  width: 100%;
}
.formitem4{
    margin-top:4rem;
    display: flex;
}
.formitem5{
  display: flex;
  justify-content: space-between;
}
.documentsection{
    overflow-y: scroll;
    width:50%;
    height:60vh;
    margin-left:4rem
}
.nameItem{
  width: 48%;
  display: flex;
  flex-direction: column;
}

.table{
    box-sizing: border-box;
    width:94%;
    font-size:small;
    box-shadow: 0px 0px 1px 0.5px gray;
    border-radius:5px;
    margin:3px;
}
.header{
    height: 50px;
    background-color: rgb(46, 73, 159);
    margin: 0px;
    color:white;
    padding: 0px;
    width: 100%;
}
.tablerow{
    background-color: rgb(241, 238, 238);
    height:40px
}
a{
    cursor: pointer;
}
.popup{
    background-color:white;
    padding:1.5rem;
    position:absolute;
    top:50%;
    left:70%;
    z-index:50;
    border-radius:5px;
    box-shadow: 0px 0px 15px 0.5px gray;
}
.prompt-box{
    background-color: white;
    padding:1rem;
    position:absolute;
    top:30%;
    width:43vw;
    height:60vh;
    border-radius:5px;
    box-shadow: 0px 0px 15px 0.5px gray;
}


.mainSection{
    height:85vh;
    padding: 2rem;
    margin:2rem;
    display: flex;
    justify-content: center;
}
.contentsection{
    box-shadow: 0px 0px 15px 0.5px rgb(222, 222, 222);
    border-radius: 1rem;
    width:40vw;
    height:100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.dropdiv{
    margin:1rem;
    height: 53vh;
}
.dropcontainer{
    
    border-radius: 0.5rem;
    display:flex;
    align-items:center
}
.dropzone{
    background-color: rgb(241, 240, 240);
    border:2px dashed gray;
    border-radius:1rem;
    width: 100%;
    height:20vh;
    padding: 3rem 0 3rem 0;
    display: flex;
    justify-content: center;
}
.dropzone > p{
    color:gray;
    margin-left:10px
}
.fileimg{
    width:4rem
}
.template{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:0.2rem;
    height:50px
}
.template > a{
    text-decoration:none;
    font-size: 0.9rem;
    margin:0.5rem;
}
.submitdiv{
    margin: 5rem;
}
.btnsubmit{
    display: flex;
}
.btnsubmit > div {
    width: 100%;
    display: flex;
    justify-content: center;
}
.errordiv{
    width: inherit;
    color:red;
    text-align: center;
    overflow-wrap: break-word;
    height: 20px;
}
.successdiv{
    color:green;
    text-align: right;
    font-weight: bold;
    margin: 0px;
    height:22px
}
.Note{
    font-size: 0.8rem;
    margin: 0px;
}
#slider-container{
    position: fixed;
    z-index: 100;
    top:43px;
    left: 100px;
}

#slider{
    width:500px;
    height: 800px;
    /* border: 1px solid black; */
}

#slider h3{
    color: white;
    width: 330px;
    font-size: 22px;
    margin: 0;
}

#slider img{
    margin-top: 60px;
}
.slide-navigationButtons{
    display: inline-block;
    margin-left: 50%;
}

.rounded-button{
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-left: 10px
    /* padding-left: 10px; */
}

.slider-activeButton{
    background-color: #035fff;
}

.blue-curve{
    background:linear-gradient(to bottom right, #045bfc 61%, #4842d8 68%);
}

.red-curve{
    background:linear-gradient(to bottom right, #e46a6a  61%, #f70101 68%);
}

.yellow-curve{
    background:linear-gradient(to bottom right, #eece6e 61%, #ffbe26 68%);
}

body{
    height: 100vh;
    /* width: 100vw; */
    overflow:auto;
    font-family: 'Open Sans', sans-serif;
}

#preLogin-container{
    display: flex;
    position: unset;

}
#perLogin-TestApp{
  position: relative;  
}



#leftBox{
    display: inline-block;
    width: 71%;
    height: 100vh;
}

#curve{
   position: fixed;
    width: 314%;
    height: 362%;
    top: -117%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 80%;
    right: 42%;
}

#rightBox{
   display: block;
   width: 49%;
   position: inherit;
   height: 100vh;
   justify-content: center;
   align-items: center;
   padding-top: 85px;
   padding-left: 80px;
   font-family: 'Open Sans', sans-serif;
}

.tokenTime{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

#login-form{
    background-color: white;
    width:300px;
    height: auto;
    padding: 20px 20px;
    border-radius: 7px;
    box-shadow: 0 2px 10px 3px #E3E3E3;
    margin-top: 120px;
    padding-top: 15px;
}

#login-form input{
    width: 100%;
    height: 30px;
    border-radius: 3px;
}

.submit-button{
    width: 100%;
    height: 32px;
    background-color: #035fff ;
    font-size: 13px;
    font-weight: 600;
    color: white;
    border-radius: 5px;
    box-shadow:0 0 4px 3px rgba(3, 95, 255, 0.2);
    border:none;
}

.loginForm-title{
    padding-top: 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    padding-bottom: 15px;
    margin-top: 0;
    color:#4d5053  ;
}
.testVerify{
    margin-left: 30%;
    margin-top: 12%;
    background-color: white;
}
.marginBottom{
    margin-bottom: 9px;
}

input::-webkit-input-placeholder {
    color: #a7a7a7 !important;
    font-style: italic !important;
    font-size: 12px;
  }
  
  input:-moz-placeholder {
    /* Firefox 18- */
    color: #b7a7a7 !important;
    font-style: italic !important;
    font-size: 12px;

  }
  
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #b7a7a7 !important;
    font-style: italic !important;
    font-size: 12px;

}
  
  
input:-ms-input-placeholder {
    color: #b7a7a7 !important;
    font-style: italic !important;
}

#loginPageTitle{
    display: flex;
    width:300px;
    align-items: baseline;
}
 
#loginPageTitle img{
    width:33px;
    height: 18px;
}

#loginPageTitle h4{
    font-weight: bolder;
    font-size: 22px;
    padding: 0 10px 0 15px;
    margin-top: 0;
}

#loginPageTitle span{
    color:#96a1ab;
    font-size: 12px;
    margin-top: 6px;
}

.marginTop{
    margin-top:20px;
    font-size: 12px;
    font-weight: bolder;
    color:#035fff;
    width:300px;
}

.company-logo{
    width: 250px;
    margin-top: 110px;
    position: fixed;
    top:65%;
}

.powered{
    color:#96a1ab;
    font-size: 12px;
    font-weight: normal;
    font-weight: initial;
    margin-bottom:8px;
    margin-right:50px;


}

#forgot-password{
    color:#035fff !important;
}


.fullSize{
    width: 100%
}
@media(max-width:997){
    #slider img{
        width:75%;
    }
}
@media screen and (max-width: 600px) {
    .testVerify{
        margin: 65px 10px 0px 10px;
    }
}
@media(max-width:768px){
    #leftBox{
        position: absolute;
        left:0px;
        display: block;
        width: 100%;
  margin-left:0px;
    float: left;
   
    }
    #slider{
        display: none;

    }
    #curve{
        position: fixed;
         width: 314%;
         height: 362%;
         top: -117%;
         border-top-right-radius: 50%;
         border-bottom-right-radius: 80%;
         right: -22%;
     }
    
    #rightBox{
        position:absolute;
        left:-50px;
        margin:0px;
    }
    .powered{
        margin-right:0;
    }
    }
   select.selectProject.form-control{
    padding: 1px 0px !important;
   }
   .overflowAuto{
      overflow: auto;
   }
   .calendar{
       width:792px !important;
   }




