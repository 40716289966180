.inputWidth {
  width: 100% !important;
}
.pageHeading {
  position: absolute;
  top: 0;
  left: calc((89% - 372px) / 2);
}

.formSize {
  padding: 40px 0px 0px 20px !important;
}

.inner-header {
  display: flex;
  position: absolute;
  left: 50px;
  top: 0;
  padding: 15px;
  justify-content: center;
  width: calc(100% - 50px);
}

.inner-header div {
  margin-left: auto;
}

.inner-header div.margin-auto {
  margin: 0 auto;
}

.page-title {
  font-size: 20px;
  margin: 0;
}

.without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}
input[type="time"]::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: -10px;
}
.hideChckbox {
  display: none !important;
}
.recordBtn {
  position: absolute;
  margin-top: 20px;
}
.stopBtn {
  position: absolute;
  margin-left: 70px;
  margin-top: 20px;
}
.MuiAutocomplete-hasClearIcon div.MuiAutocomplete-endAdornment {
  top: calc(50% - 30px) !important;
}
