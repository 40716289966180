.sidebar {
  padding: 15px;
}
.menuItem{
  display: flex;
  align-items: center;
  height:30px;
  margin-bottom:2px
}
.menuIcon{
  display: flex;
  color: gray;
  align-items: center;
  margin:0px 3px;
}
.navNames {
  color: blue;
  margin-left: 0px;
  text-decoration: none;
  display: block;
}
.navNames:hover {
  color: grey;
}
